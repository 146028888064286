const Images = {
    Photo1: "https://i.imgur.com/rWHSgkN.jpg",
    Photo2:"https://i.imgur.com/dhDG8fI.jpg",
    Photo3: "https://i.imgur.com/UPAnpCy.jpg",
    Photo4: "https://i.imgur.com/4dsm3LK.jpg",
    Photo5: "https://i.imgur.com/LILE6uV.png",
    Photo6: "https://i.imgur.com/lkpLcPR.jpg"


}

module.exports = Images